/*----------------------------------------*/
/*  43 - Pages - About Us
/*----------------------------------------*/
.mt-110 {
    margin-top: 110px
}
.height-100vh {
    min-height: 100vh;
}

.comming-soon-area{
    padding: 60px 0;
    @include breakpoint(medium){
        padding: 40px 0;
    }
    @include breakpoint(max-small){
        padding: 40px 0
    }
    @include breakpoint(x-small){
        .container{
            max-width: 300px;
        }
    }
    .comming-soon-wrap {
        position: relative;
        z-index: 99;
        .comming-soon-top{
            margin: 0 0 54px;
            @include breakpoint(max-small){
                margin: 0 0 30px;
            }
            > h3{
                color: #fff;
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 12px;
                line-height: 1;
                margin: 58px 0 41px;
                @include breakpoint(normal){
                    margin: 29px 0 20px;
                }
                @include breakpoint(medium){
                    margin: 40px 0 20px;
                }
                @include breakpoint(max-small){
                    letter-spacing: 6px;
                    margin: 30px 0 20px;
                }
            }
            > p{
                color: #fff;
                font-size: 16px;
                width: 66%;
                margin: 0 auto;
                @include breakpoint(normal){
                    width: 85%;
                }
                @include breakpoint(medium){
                    width: 82%;
                }
                @include breakpoint(max-small){
                    width: 98%;
                }
                @include breakpoint(small){
                    width: 100%;
                }
            }
        }
    }
    &.overlay{
        position: relative;
        &:before{
            background-color: #000;
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            opacity: 0.7;
            position: absolute;
            right: 0;
            width: 100%;
            z-index: 9;
        }
    }
}

.subscribe-area{
    position: relative;
    z-index: 99;
    > h4{
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 3px;
        margin: 0 0 14px;
        @include breakpoint(max-small){
            letter-spacing: 2px;
        }
    }
    .subscribe-form{
        > form{
            position: relative;
            .mc-news {
                display: none;
            }
            .clear {
                position: absolute;
                top: 0;
                right: 0;
                input{
                    background: transparent none repeat scroll 0 0;
                    border: medium none;
                    border-radius: 50px;
                    color: #e0e0e0;
                    cursor: pointer;
                    font-weight: 700;
                    padding: 2px 40px 2px 30px;
                    width: auto;
                    @include breakpoint(max-small){
                        padding: 2px 25px 2px 18px;
                    }
                }
                &:hover{
                    input{
                        color: #8f9495;
                    }
                }
                &:before{
                    background: #fff none repeat scroll 0 0;
                    content: "";
                    height: 35px;
                    position: absolute;
                    top: 10px;
                    transition: all 0.3s ease 0s;
                    width: 2px;
                    left: 0;
                    @include breakpoint(max-small){
                        height: 29px;
                    }
                }
            }
            input{
                background: transparent;
                border: 1px solid #eceff8;
                color: #fff;
                font-size: 14px;
                height: 55px;
                padding: 2px 146px 2px 40px;
                width: 100%;
                font-weight: 300;
                border-radius: 50px;
                font-family: 'Rubik',sans-serif;
                &::-moz-placeholder{
                    color: #fff !important;
                    opacity: 1;
                }
                &::-webkit-input-placeholder{
                    color: #fff !important;
                    opacity: 1;
                }
                @include breakpoint(max-small){
                    padding: 2px 117px 2px 20px;
                    height: 50px;
                }
            }
        }
    }
    &.mt-110{
        margin-top: 70px;
        @include breakpoint(medium){
            margin-top: 70px;
        }
        @include breakpoint(max-small){
            margin-top: 50px;
        }
    }
}

.DateCountdown {
    .time_circles{
        display: flex;
        flex-wrap: wrap;
        canvas{
            width: 100% !important;
            height: auto !important;
            position: absolute;
            left: 0;
            top: 0;
        }
        > div{
            margin-top: 86px;
            text-align: center;
            display: flex;
            flex-direction: column;
            flex: 1 0 25%;
            position: static;
            width: 25% !important;
            z-index: 99;
            @include breakpoint(normal){
                margin-top: 60px;
            }
            @include breakpoint(medium){
                margin-top: 50px;
            }
            @include breakpoint(max-small){
                margin-top: 0px;
            }
            @include breakpoint(small){
                margin-top: 26px;
            }
            > h4 {
                color: #fff;
                font-family: "Rubik", sans-serif;
                font-size: 18px !important;
                font-weight: 400;
                margin: 89px 0 0;
                order: 2;
                padding: 0;
                text-align: center;
                text-transform: capitalize;
                @include breakpoint(medium){
                    margin: 64px 0 0;
                }
                @include breakpoint(max-small){
                    font-size: 16px !important;
                    margin: 25px 0 0;
                }
                @include breakpoint(small){
                    font-size: 16px !important;
                    margin: 50px 0 0;
                }
            }
            > span{
                color: #fff;
                display: block;
                font-family: "Teko",
                sans-serif;
                font-size: 60px !important;
                font-weight: 400;
                line-height: 47px !important;
                margin-top: 16px;
                text-align: center;
                width: 100%;
                @include breakpoint(medium){
                    font-size: 50px !important;
                }
                @include breakpoint(max-small){
                    font-size: 26px !important;
                    margin-top: 12px;
                }
            }
        }
    }
}

