/*----------------------------------------*/
/*  36 - Pages - Checkout
/*----------------------------------------*/
.checkout-area {
	padding: 80px 0 75px;
}
.coupon-accordion {
	h3 {
		background-color: $white-smoke_color;
		border-top: 3px solid $primary-color;
		font-size: 14px;
		font-weight: 400;
		list-style: outside none none;
		margin: 0 0 30px;
		padding: 16px 32px 16px 56px;
		position: relative;
		width: auto;
		text-transform: none;
		&:before {
			color: $primary-color;
			content: "\f133";
			display: inline-block;
			font-family: 'Font Awesome 5 Free';
			font-weight: 600;
			left: 24px;
			position: absolute;
			top: 16px;
		}
	}
	span {
		cursor: pointer;
		@extend %basetransition;
		text-transform: none;
		&:hover {
			color: $primary-color;
		}
	}
	.coupon-content {
		border: 1px solid $border-color;
		margin-bottom: 20px;
		display: none;
		padding: 20px;
		.coupon-info {
			p {
				margin-bottom: 0;
				&.form-row-first,
				&.form-row-last {
					label {
						display: block;
						span {
							&.required {
								color: $bg-red_color;
								font-weight: 700;
							}
						}
					}
					input {
						border: 1px solid $border-color;
						height: 36px;
						margin: 0 0 14px;
						border-radius: 0;
						max-width: 100%;
						padding: 0 0 0 10px;
						width: 370px;
						background-color: transparent;
					}
				}
				&.form-row {
					input[type="submit"] {
						background: $body-text_color none repeat scroll 0 0;
						border: medium none;
						border-radius: 0;
						box-shadow: none;
						color: $bg-white_color;
						cursor: pointer;
						display: inline-block;
						float: left;
						font-size: 12px;
						height: 40px;
						line-height: 40px;
						margin-right: 15px;
						padding: 0 30px;
						text-shadow: none;
						text-transform: uppercase;
						@extend %basetransition;
						// white-space: nowrap;
						width: inherit;
						&:hover {
							background: $primary-color;
						}
					}
					input[type="checkbox"] {
						height: inherit;
						position: relative;
						top: 2px;
						width: inherit;
					}
					label {
						margin-top: 7px;
					}
				}
				&.checkout-coupon {
					input[type="submit"] {
						background: $primary-color;
					}
				}
				&.lost-password {
					margin-top: 15px;
					a {
						&:hover {
							color: $primary-color;
						}
					}
				}
			}
		}
	}
}

.coupon-checkout-content {
	margin-bottom: 30px;
	display: none;
	.coupon-info {
		.checkout-coupon {
			margin-bottom: 0;
			input[type="text"] {
				background-color: transparent;
				border: 1px solid $border-color;
				border-radius: 0;
				height: 36px;
				padding-left: 10px;
				width: 170px;
			}
			input[type="submit"] {
				background: $body-text_color;
				border: medium none;
				border-radius: 0;
				color: $bg-white_color;
				height: 36px;
				cursor: pointer;
				margin-left: 6px;
				padding: 5px 10px;
				-webkit-transition: all 0.3s ease 0s;
				transition: all 0.3s ease 0s;
				width: inherit;
				&:hover {
					background: $primary-color;
				}
			}
			.coupon-inner_btn {
				@include breakpoint (xx-small) {
					margin: 28px 0 0 !important;
				}
			}
		}
	}
}

.checkbox-form {
	h3 {
		border-bottom: 1px solid $border-color;
		font-size: 25px;
		margin: 0 0 25px;
		padding-bottom: 10px;
		text-transform: uppercase;
		width: 100%;
		@include breakpoint (xx-small) {
			font-size: 18px;
		}
	}
	.country-select {
		margin-bottom: 25px;
		.myniceselect {
			&.nice-select {
				span {
					height: 40px;
					line-height: 40px;
					display: inline-block;
					width: 100%;
					padding: 0 0 0 15px;
				}
			}
		}
	}
	.checkout-form-list {
		margin-bottom: 30px;
		input[type="text"],
		input[type="password"],
		input[type="email"] {
			background: $bg-white_color;
			border: 1px solid $border-color;
			border-radius: 0;
			height: 42px;
			width: 100%;
			padding: 0 0 0 10px;
		}
		input[type="checkbox"] {
			display: inline-block;
			height: inherit;
			margin-right: 10px;
			position: relative;
			top: 2px;
			width: inherit;
		}
		&.create-acc {
			margin-bottom: 15px !important;
			label {
				display: inline-block;
			}
		}
	}
	.country-select,
	&.checkout-form-list {
		label {
			margin: 0 0 5px;
			display: block;
			span {
				&.required {
					color: $primary-color;
					display: inline-block;
					font-size: 24px;
					line-height: 16px;
					position: relative;
					top: 5px;
				}
			}
		}
	}
}
.different-address {
	h3 {
		label {
			display: inline-block;
			margin-right: 20px;
			font-size: 25px;
			margin-bottom: 10px;
			@include breakpoint (xx-small) {
				font-size: 18px;
			}
		}
		input {
			height: inherit;
			line-height: normal;
			margin: 4px 0 0;
			position: relative;
			top: 0;
			width: auto;
		}
	}
}

.order-notes {
	.checkout-form-list-2 {
		margin-bottom: 0;
		@include breakpoint (max-medium) {
			margin-bottom: 30px;
		}
	}
	textarea {
		background-color: transparent;
		border: 1px solid $border-color;
		height: 90px;
		padding: 15px;
		width: 100%;
	}
}

.create-account,
#ship-box-info {
	display: none;
}
.your-order {
	background: $white-smoke_color;
	padding: 30px 40px;
	@include breakpoint (xx-small) {
		padding: 30px 20px;
	}
	h3 {
		border-bottom: 1px solid $border-color;
		font-size: 25px;
		padding-bottom: 10px;
		text-transform: uppercase;
		width: 100%;
		@include breakpoint (xx-small) {
			font-size: 18px;
		}
	}
	.your-order-table {
		table {
			background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
			border: medium none;
			width: 100%;
			th,
			td {
				padding-left: 0;
			}
			thead {
				background-color: $white-smoke_color;
				tr {
					th {
						border-top: medium none;
						font-weight: normal;
						text-align: center;
						text-transform: uppercase;
						vertical-align: middle;
						// white-space: nowrap;
						width: 250px;
					}
					th,
					td {
						border-bottom: 1px solid $border-color;
						border-right: medium none;
						font-size: 14px;
						padding: 15px 0;
						text-align: left;
					}
				}
			}
			.cart_item {
				&:hover {
					background: $white-smoke_color;
				}
			}
			.order-total {
				td {
					border-bottom: medium none;
					span {
						font-size: 20px;
					}
				}
				th {
					border-bottom: medium none;
					font-size: 18px;
				}
			}
		}
	}
}

.payment-method {
	margin-top: 20px;
	.payment-accordion {
		.card {
			border: 0;
			border-radius: 0;
			background: $white-smoke_color;
		}
		.card-header {
			padding: 0;
			margin-bottom: 0;
			background-color: $white-smoke_color;
			border-bottom: 0;
		}
		.card-body {
			padding: 0;
		}
		.panel.panel-default {
			margin-bottom: 20px;
		}
		.panel-title {
			font-weight: 600;
			font-size: 18px;
			cursor: pointer;
			a {
				&:hover {
					color: $primary-color !important;
				}
			}
		}
	}
}

.order-button-payment {
	input {
		background: $body-text_color;
		border: medium none;
		color: $bg-white_color;
		font-size: 17px;
		font-weight: 600;
		height: 50px;
		margin: 20px 0 0;
		padding: 0;
		text-transform: uppercase;
		@extend %basetransition;
		width: 100%;
		border: 1px solid transparent;
		cursor: pointer;
		&:hover {
			background: $primary-color;
		}
	}
}
