/*----------------------------------------*/
/*  22 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
	border-top: 1px solid $border-color;
	padding-top: 25px;
	.breadcrumb-content {
		> h2 {
			font-weight: $heading-font_weight;
			text-align: left;
			margin-bottom: 0;
			padding-bottom: 20px;
		}
		ul {
			> li {
				display: inline-block;
				padding-left: 20px;
				position: relative;
				&:first-child {
					padding-left: 0;
				}
				&:before {
					content: "\f054";
					font-family: 'Font Awesome 5 Free';
					font-weight: 600;
					position: absolute;
					right: -15px;
					top: 2px;
					font-size: 10px;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
