/*----------------------------------------*/
/*  40 - Pages - Privacy Policy
/*----------------------------------------*/
.privacy-policy_area {
	padding: 50px 0 55px;
	.privacy-policy_info {
		.privacy-policy_list {
			padding-bottom: 20px;
			> h3,
			h4 {
				font-size: 20px;
				margin-bottom: 0;
				padding-bottom: 15px;
				@include breakpoint(max-small) {
					font-size: 16px;
					line-height: 24px;
				}
			}
			> p {
				margin-bottom: 0;
				color: $grey-color;
				&.additional-desc {
					padding-top: 15px;
				}
				> a {
					padding-left: 5px;
					color: $grey-color;
				}
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}
