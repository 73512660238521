/*----------------------------------------*/
/*  12 - Components - Animation
/*----------------------------------------*/
/* Umino's Animation Style One */
.slick-active {
	&.animation-style-01 {
		.slider-content {
			> h3 {
				animation-name: fadeInLeft;
				animation-delay: 1.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h4 {
				animation-name: fadeInLeft;
				animation-delay: 1s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> a {
				&.product-price {
					> span {
						animation-name: fadeInLeft;
						animation-delay: 2s;
						animation-duration: 1.3s;
						animation-fill-mode: both;
						display: block;
					}
				}
			}
		}
	}
	&.animation-style-02 {
		.slider-content,
		.additional-slider_content {
			> h5 {
				animation-name: fadeInLeft;
				animation-delay: 1s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h4 {
				animation-name: fadeInLeft;
				animation-delay: 0.7s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> span {
				animation-name: fadeInRight;
				animation-delay: 1.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
				display: block;
			}
			> h2 {
				animation-name: fadeInRight;
				animation-delay: 2s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h3 {
				animation-name: fadeInRight;
				animation-delay: 2.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInRight;
				animation-delay: 3s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
	&.animation-style-03 {
		.slider-content {
			> h4 {
				animation-name: fadeInLeft;
				animation-delay: 1s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h3 {
				animation-name: fadeInLeft;
				animation-delay: 1.2s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> span {
				animation-name: fadeInLeft;
				animation-delay: 1.4s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInLeft;
				animation-delay: 1.7s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
	&.animation-style-04 {
		.slider-content {
			> h4 {
				animation-name: fadeInLeft;
				animation-delay: 0.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h3 {
				animation-name: fadeInLeft;
				animation-delay: 0.7s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h5 {
				animation-name: fadeInLeft;
				animation-delay: 0.9s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> p {
				animation-name: fadeInLeft;
				animation-delay: 1.3s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInLeft;
				animation-delay: 1.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> a {
				&.product-price {
					> span {
						animation-name: fadeInLeft;
						animation-delay: 1.5s;
						animation-duration: 1.3s;
						animation-fill-mode: both;
						display: block;
					}
				}
			}
		}
	}
}

// Kyframes

/*Top Up Keyframes*/
@-webkit-keyframes picoSlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes picoSlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
// Begin Slide In Right Kyframes
.slide-in-right {
	-webkit-animation: slide-in-right 4s linear infinite alternate both;
	animation: slide-in-right 4s linear infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:0:13
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
// Slide In Right Kyframes End Here
