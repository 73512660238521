/*----------------------------------------*/
/*  19 - Components - Product Tab
/*----------------------------------------*/
/* ---Umino's Product Tab Area--- */
.umino-product-tab_area {
	padding: 60px 0 0;
	@include breakpoint(max-small) {
		padding: 55px 0 0;
	}
	.umino-product-tab_nav {
		.product-tab {
			display: flex;
			justify-content: center;
			padding-bottom: 30px;
			@include breakpoint(max-small) {
				padding-bottom: 25px;
			}
			> ul {
				&.product-menu {
					> li {
						> a {
							position: relative;
							font-weight: $body-font_weight;
							padding: 0 25px 15px;
							position: relative;
							margin-right: 15px;
							display: block;
							font-size: 28px;
							@include breakpoint(max-small) {
								margin-right: 0;
								padding: 0 25px 0 0;
								font-size: 20px;
							}
							@include breakpoint(xx-small) {
								padding: 0 10px 15px 0;
								font-size: 16px;
							}
							&.active {
								font-weight: 600;
								@extend %basetransition;
								&:before {
									content: "";
									background-color: $primary-color;
									position: absolute;
									bottom: 0;
									left: 50%;
									transform: translateX(-50%);
									height: 6px;
									width: 48px;
									border-radius: 3px;
									@include breakpoint(max-small) {
										display: none;
									}
								}
							}
							&:hover {
								color: $heading-color !important;
							}
						}
					}
				}
			}
		}
	}
}

/* ---Umino's Product Tab Area Two--- */
.umino-product-tab_area-2 {
	.product-tab_wrap {
		display: flex;
		justify-content: flex-end;
		.product-tab {
			padding-bottom: 0;
			justify-content: flex-end;
			@include breakpoint(max-small) {
				padding-top: 30px;
			}
			> ul {
				display: block;
				@include breakpoint (max-small) {
					display: flex;
				}
				> li {
					margin-bottom: 15px;
					@include breakpoint(max-small) {
						margin-bottom: 0;
					}
					@include breakpoint(max-x_small) {
						max-width: 25%;
					}
					&:last-child {
						margin-bottom: 0;
					}
					> a {
						> img {
							border: 2px solid $border-color;
							@extend %basetransition;
						}
						&.active {
							> img {
								border-color: $primary-color;
							}
						}
					}
					&:hover {
						> a {
							> img {
								border-color: $primary-color;
							}
						}
					}
				}
			}
		}
	}
	.product-tab-additional_stuff {
		background-color: $white-smoke_color;
		padding: 55px 30px 60px;
		text-align: center;
		> h3 {
			&.heading {
				margin-bottom: 0;
				position: relative;
				padding-bottom: 45px;
				&:before {
					background-color: $primary-color;
					content: "";
					position: absolute;
					bottom: 30px;
					height: 6px;
					width: 60px;
					left: 50%;
					transform: translateX(-50%);
					border-radius: 3px;
				}
			}
		}
		.price-box {
			padding-bottom: 10px;
			@include new-price;
			@include old-price;
		}
		.rating-box {
			padding-bottom: 10px;
			> ul {
				> li {
					display: inline-block;
					> i {
						color: $primary-color;
					}
				}
			}
		}
		.umino-countdown_area {
			> h3 {
				&.heading {
					margin-bottom: 0;
					font-size: 16px;
					padding-bottom: 25px;
				}
			}
			.umino-countdown {
				background-color: $bg-white_color;
				.count {
					padding: 5px 0;
					@include breakpoint(max-medium) {
						width: 22%;
					}
					span {
						&.count-amount {
							font-size: 20px;
							@include breakpoint(max-medium) {
								font-size: 16px;
							}
						}
						&.count-period {
							@include breakpoint(normal) {
								font-size: 13px;
								text-transform: capitalize;
							}
						}
					}
				}
			}
		}
	}
}

/* ---Umino's Product Tab Area Three--- */
.sp-product-tab_area {
	background-color: $bg-white_color;
	padding: 0 0 60px;
	.short-desc {
		margin: 0 auto;
		text-align: center;
		> p {
			margin-bottom: 0;
			padding-bottom: 15px;
		}
	}
}
