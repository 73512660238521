/*----------------------------------------*/
/*  15 - Components - Product
/*----------------------------------------*/
.umino-product_area {
	padding-top: 55px;
}

/* ---Umino's Product Slider--- */
.umino-product_slider,
.umino-product_slider-2,
.umino-product_slider-3,
.umino-product_slider-4,
.umino-product-tab_slider,
.umino-product-tab_slider-2,
.umino-content_wrapper {
	.slide-item {
		.single-product {
			padding: 30px 10px;
			border-radius: 3px;
			@extend %basetransition;
			.product-img {
				position: relative;
				text-align: center;
				> a {
					> img {
						width: 100%;
					}
				}
				.add-actions {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 100%;
					> ul {
						> li {
							display: inline-block;
							opacity: 0;
							@extend %basetransition;
							&:nth-child(1) {
								transition-delay: 100ms;
							}
							&:nth-child(2) {
								transition-delay: 150ms;
							}
							&:nth-child(3) {
								transition-delay: 200ms;
							}
							&:nth-child(4) {
								transition-delay: 250ms;
							}
							> a {
								background-color: $primary-color;
								border-radius: 100%;
								display: block;
								width: 40px;
								height: 40px;
								line-height: 40px;
								text-align: center;
								&:hover {
									background-color: $bg-red_color;
									color: $bg-white_color !important;
								}
								> i {
									font-size: 20px;
								}
							}
						}
					}
				}
			}
			.product-content {
				padding-top: 25px;
				.product-desc_info {
					.price-box {
						padding-bottom: 15px;
						@include new-price;
						@include old-price;
					}
					> h6 {
						font-weight: $body-font_weight;
						&.product-name {
							margin-bottom: 0;
							padding-bottom: 5px;
							font-size: 15px;
							@include breakpoint(normal) {
								font-size: 13px;
							}
							@include breakpoint(medium) {
								font-size: 13px;
							}
						}
					}
					.rating-box {
						padding-bottom: 10px;
						> ul {
							> li {
								display: inline-block;
								> i {
									color: $primary-color;
								}
							}
						}
					}
				}
			}
			&:hover {
				.product-img {
					.add-actions {
						> ul {
							> li {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	@include umi-slick_dots;
	.slick-dots {
		position: relative;
		bottom: 0;
		padding: 25px 0 0;
		margin-bottom: -5px;
	}
}

/* ---Umino's Product Area Three--- */
.umino-product_area-3 {
	&.section-space_add {
		padding-bottom: 60px;
	}
}
/* ---Umino's Product Area Four--- */
.umino-product_area-4 {
	.combine-banner-with_product {
		padding-top: 0;
		@include breakpoint(max-small) {
			padding-bottom: 30px;
		}
		.banner-item {
			.banner-content {
				@include absCenter;
				top: 75%;
				width: 70%;
				> h4,
				span {
					text-transform: uppercase;
				}
				> h4 {
					font-size: 26px;
				}
				> span {
					padding-bottom: 20px;
				}
				&.banner-content-2 {
					@include absCenter;
					> a {
						color: $primary-color;
						> i {
							font-size: 28px;
						}
					}
				}
			}
		}
	}
}
/* ---Umino's Product Area Five--- */
.umino-product_area-5 {
	padding-top: 105px;
	@include breakpoint(max-normal) {
		padding-top: 55px;
	}
	.umino-section_title {
		> h3 {
			@include breakpoint(xx-small) {
				font-size: 18px;
			}
		}
	}
	.umino-product_slider-5 {
		.slick-list {
			margin: 0 -15px;
			.slick-slide {
				margin: 0 15px;
			}
		}
	}
}

/* ---Umino's Product Tab Slider--- */
.umino-product-tab_slider {
	.slide-item {
		.single-product {
			.product-content {
				.product-desc_info {
					> h6 {
						font-weight: $body-font_weight;
						&.product-name {
							margin-bottom: 0;
							padding-bottom: 5px;
							font-size: 15px;
						}
					}
				}
			}
		}
	}
}

/* ---Umino's Product Tab Slider Two--- */
.umino-product-tab_slider-2 {
	.slide-item {
		.single-product {
			padding: 0;
		}
	}
	@include umi-slick_dots;
	.slick-dots {
		bottom: 0;
		padding: 0;
		@include breakpoint(max-small) {
			display: none !important;
		}
	}
}

/* ---Umino's Countdown--- */
.umino-countdown {
	border: 2px solid $primary-color;
	border-radius: 50px;
	text-align: center;
	.count {
		display: inline-block;
		position: relative;
		width: 20%;
		padding: 5px 0;
		span {
			&.count-amount {
				display: block;
				font-weight: 600;
				font-size: 18px;
				@include breakpoint(normal) {
					font-size: 15px;
				}
			}
			&.count-period {
				display: block;
				font-size: 14px;
				text-transform: uppercase;
				@include breakpoint(normal) {
					font-size: 13px;
					text-transform: capitalize;
				}
			}
		}
	}
}

/* ---Umino's List Product Area--- */
.umino-list-product_area {
	padding: 55px 0;
	[class*="col-"] {
		&:nth-child(n + 2) {
			.umino-section_title {
				@include breakpoint(max-medium) {
					padding-top: 50px;
				}
			}
		}
	}
}

/* ---Umino's List Product Slider--- */
.list-product_slider,
.list-product_slider-2 {
	.slide-item {
		.single-product {
			display: flex;
			align-items: center;
			.product-img {
				width: 130px;
			}
			.product-content {
				width: calc(100% - 130px);
				padding-left: 15px;
				.product-desc_info {
					.price-box {
						padding-bottom: 10px;
						@include new-price;
						.new-price {
							font-size: 16px;
						}
						@include old-price;
						.old-price {
							font-size: 15px;
						}
					}
					> h6 {
						font-weight: $body-font_weight;
						&.product-name {
							margin-bottom: 0;
							padding-bottom: 5px;
						}
					}
					.rating-box {
						padding-bottom: 10px;
						> ul {
							> li {
								display: inline-block;
								> i {
									color: $primary-color;
								}
							}
						}
					}
				}
			}
		}
	}
}

/* ---Umino's Multiple Product Area--- */
.multiple-product_area {
	padding-top: 55px;
	.list-product_slider-2 {
		@include breakpoint(max-medium) {
			padding-bottom: 55px;
		}
		.slide-item {
			.single-product {
				padding: 25px 15px 15px;
				@include breakpoint(normal) {
					padding: 25px 0 15px;
				}
				.product-img {
					width: 85px;
					@include breakpoint(normal) {
						width: 60px;
					}
				}
				.product-content {
					width: calc(100% - 85px);
					@include breakpoint(normal) {
						width: calc(100% - 60px);
					}
					.product-desc_info {
						> h6 {
							&.product-name {
								> a {
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
	.multiple-product_banner {
		padding-top: 0;
		@include breakpoint(max-small) {
			padding-top: 25px;
		}
		[class*="col-"] {
			&:first-child {
				.banner-item {
					margin-bottom: 30px;
				}
			}
		}
		.banner-item {
			position: relative;
			.banner-content {
				position: absolute;
				top: 30px;
				left: 30px;
				z-index: 1;
				> span {
					color: $grey-color;
					display: block;
					padding-bottom: 5px;
				}
				> h4,
				h3 {
					font-size: 16px;
					font-weight: 600;
					margin-bottom: 0;
				}
				> h4 {
					padding-bottom: 5px;
				}
				> a {
					display: block;
					padding-top: 10px;
					> i {
						color: $primary-color;
						font-size: 24px;
					}
				}
			}
		}
	}
	.umino-product_slider-4 {
		.slick-dots {
			position: absolute;
			bottom: -50px;
			left: 0;
			right: 0;
			text-align: center;
			padding: 0 30px;
			z-index: 8;
			@include breakpoint(max-medium) {
				display: none !important;
			}
			li {
				display: inline-block;
				margin: 0 3px;
				cursor: pointer;
				font-size: 0;
				width: 12px;
				height: 12px;
				background: #bbb;
				border-radius: 100%;
				@extend %basetransition;
				button {
					border: none;
				}
				&.slick-active {
					background-color: #777;
				}
				&:hover {
					background-color: #777;
				}
			}
		}
	}
}
