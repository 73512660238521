/*----------------------------------------*/
/*  23 - Components - Slider Navigation
/*----------------------------------------*/
/* ---Slider Navigation Style One---  */
.slider-navigation_style-1 {
	.slick-arrow {
		background-color: rgba(0, 0, 0, .5);
		box-shadow: 0px 0px 9.8px 0.2px rgba(0, 0, 0, 0.05);
		font-size: 24px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		display: block;
		color: $bg-white_color !important;
		text-align: center;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		border-radius: $umino-border_radius;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
		visibility: hidden;
		left: 20px;
		@include breakpoint (max-small) {
			display: none !important;
		}
		&:hover {
			background-color: $bg-black_color;
		}
	}
	.slick-next {
		left: auto;
		right: 20px;
	}
	&:hover {
		.slick-arrow {
			opacity: 1;
			visibility: visible;
		}
	}
}

/* ---Slider Navigation Style Two---  */
.slider-navigation_style-2 {
	.slick-arrow {
		background-color: $bg-white_color;
		border: 1px solid $border-color;
		border-radius: 100%;
		font-size: 18px;
		width: 30px;
		height: 30px;
		line-height: 29px;
		display: block;
		color: $border-color !important;
		text-align: center;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		bottom: -70px;
		margin-left: 15px;
		&.slick-prev {
			left: auto;
			transform: translate(-50%);
			right: 50%;
		}
		&.slick-next {
			left: 50%;
			transform: translate(-50%);
			right: auto;
		}
		&:hover {
			background-color: $primary-color;
			color: $bg-white_color !important;
		}
	}
}

/* ---Slider Navigation Style Three---  */
.slider-navigation_style-3,
.slider-navigation_style-4 {
	.slick-arrow {
		background-color: $bg-white_color;
		border: 1px solid $border-color;
		color: $border-color;
		font-size: 18px;
		display: block;
		text-align: center;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		top: -50px;
		left: auto;
		right: 40px;
		width: 30px;
		height: 30px;
		line-height: 28px;
		border-radius: 100%;
		&:hover {
			background-color: $primary-color;
			color: $bg-white_color;
		}
		&.slick-next {
			right: 0;
		}
		@include breakpoint (max-small) {
			display: none !important;
		}
	}
}

/* ---Slider Navigation Style Four---  */
.slider-navigation_style-4 {
	.slick-arrow {
		top: 50%;
		left: -10px;
		right: auto;
		width: 30px;
		height: 30px;
		line-height: 28px;
		border-radius: 0;
		transform: translateY(-50%) scale(0);
		@extend %basetransition;
		&:hover {
			background-color: $primary-color;
			color: $bg-white_color;
		}
		&.slick-next {
			right: -10px;
			left: auto;
		}
		@include breakpoint (max-small) {
			display: none !important;
		}
	}
	&:hover {
		.slick-arrow {
			transform: translateY(-50%) scale(1);
		}
	}
}

/* ---Umino's Brand Slider Pagination--- */
.umino-brand_slider,
.testimonial-slider {
	.slick-arrow {
		background-color: $bg-white_color;
		border: 1px solid $border-color-2;
		color: $border-color-2 !important;
		border-radius: 100%;
		left: auto;
		.slick-next {
			left: 0;
		}
		&:hover {
			border-color: $primary-color;
			background-color: $primary-color;
			color: $bg-white_color !important;
		}
	}
}

/* ---Single Porduct Image Slider Two--- */
.sp-img_slider-2 {
	border: 1px solid $border-color;
	a {
		> i {
			@include absCenter;
			background-color: $heading-color;
			color: $bg-white_color;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			display: block;
			border-radius: 4px;
			opacity: 0;
			visibility: hidden;
			@extend %basetransition;
			&:hover {
				background-color: $primary-color;
			}
		}
	}
	&:hover {
		a {
			> i {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
.sp-img_slider-nav {
	padding: 0 30px;
	margin-top: 30px;
	.slick-list {
		margin: 0 -15px;
		.single-slide {
			border: 1px solid $border-color;
			margin: 0 15px;
		}
	}
}

/* ---Single Porduct Image Slider Three--- */
.sp-img_slider-3 {
	@include breakpoint (medium) {
		padding: 60px 0;
	}
	@include breakpoint (max-small) {
		flex-basis: 30% !important;
		width: 30% !important;
	}
	@include breakpoint (xx-small) {
		flex-basis: 40% !important;
		width: 40% !important;
	}
	.slick-arrow {
		top: 0;
		bottom: auto;
		width: 35px;
		height: 35px;
		line-height: 35px;
		left: 50%;
		transform: translateX(-50%);
		&.slick-prev {
			@include breakpoint (max-medium) {
				top: 30px;
			}
		}
		&.slick-next {
			top: auto;
			bottom: 0;
			@include breakpoint (max-medium) {
				bottom: 60px;
			}
		}
	}
	.slick-list {
		@include breakpoint (max-medium) {
			margin-left: 0;
		}
		.slick-slide {
			@include breakpoint (max-medium) {
				margin-left: 30px;
				margin-right: 30px;
			}
		}
	}
}

/* ---Slider Dots--- */
.main-slider {
	@include umi-slick_dots;
}
