/*----------------------------------------*/
/*  26 - Section - Footer
/*----------------------------------------*/
.footer-top_area {
	padding: 30px 0;
	.newsletter-info {
		@include breakpoint(max-medium) {
			text-align: center;
			padding-bottom: 20px;
		}
		> i {
			font-size: 36px;
			vertical-align: middle;
		}
		> span {
			font-size: 18px;
			font-weight: 600;
			padding-left: 10px;
		}
	}
}
.footer-middle_area {
	padding: 55px 0 50px;
	[class*="col-"] {
		&:nth-child(-n + 2) {
			@include breakpoint (max-medium) {
				padding-bottom: 50px;
			}
		}
		&:nth-child(3) {
			@include breakpoint (max-small) {
				padding-bottom: 50px;
			}
		}
	}
	.footer-widgets_title {
		> h4 {
			font-weight: $body-font_weight;
			padding-bottom: 20px;
			font-size: 22px;
			margin-bottom: 0;
		}
		&.widgets-info_title {
			> h4 {
				padding-bottom: 25px;
			}
		}
	}
	.widget-short_desc {
		> p {
			margin-bottom: 0;
			padding-bottom: 25px;
		}
	}
	.footer-widgets {
		> ul {
			> li {
				padding-bottom: 10px;
				&:last-child {
					padding-bottom: 0;
				}
				> a {
					@include breakpoint(normal) {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						display: block;
					}
				}
			}
		}
		&.footer-contact_info {
			> ul {
				> li {
					position: relative;
					padding-left: 30px;
					padding-bottom: 0;
					margin-bottom: 15px;
					&:last-child {
						margin-bottom: 0;
					}
					&:before {
						display: none;
					}
					> a {
						@include breakpoint(normal) {
							display: inline-block;
							vertical-align: bottom;
						}
					}
					> i {
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						font-size: 24px;
					}
					> span {
						cursor: text;
						margin-right: 5px;
					}
					.cellphone-number_area {
						display: inline-block;
						vertical-align: middle;
						> a {
							display: block;
						}
					}
				}
			}
		}
		&.latest-tweets_area {
			.latest-tweets_slider {
				.twitter-single_item {
					.twitter-feed_content {
						> p {
							margin-bottom: 0;
							padding-bottom: 15px;
							> a {
								color: $bg-blue_color;
							}
						}
					}
					.twitter-feed_footer {
						> a {
							@include breakpoint(normal) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}
/* ---Footer Bottom--- */
.footer-bottom_area {
	.footer-bottom_nav {
		border-top: 1px solid $border-color;
		padding: 25px 0;
		@include breakpoint(max-small) {
			padding: 25px 0 20px;
		}
		.copyright {
			@include breakpoint(max-small) {
				text-align: center;
				padding-top: 20px;
			}
		}
		.umino-social_link {
			padding-top: 10px;
			@include breakpoint(max-small) {
				text-align: center;
				padding: 15px 0 0;
			}
			> ul {
				> li {
					> a {
						width: auto;
						height: auto;
						line-height: 1;
						&:hover {
							background-color: transparent;
							color: $primary-color !important;
						}
					}
				}
			}
		}
		.footer-logo {
			text-align: center;
		}
		.payment-method {
			margin-top: 0;
			@include breakpoint(max-medium) {
				text-align: center;
				padding-top: 15px;
			}
			@include breakpoint(max-small) {
				padding-top: 25px;
			}
			h3 {
				&.heading {
					margin-bottom: 0;
					font-size: 15px;
					padding-bottom: 10px;
					@include breakpoint(max-small) {
						padding-bottom: 15px;
					}
				}
			}
		}
	}
}
