/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/

/* ---Backgroud Color | Only For HTML Markup---*/
.bg--white_smoke {
	background-color: $white-smoke_color;
}

.bg--white {
	background-color: $bg-white_color;
}

.bg--night_rider {
	background-color: $body-text_color;
}

.bg--silver {
	background-color: $bg-silver_color;
}

.bg--nero {
	background-color: $bg-nero_color;
}

.bg--charcoal {
	background-color: $heading-color;
}

.bg--medium-turquoise {
	background-color: $primary-color;
}

.bg--licorice {
	background-color: $bg-licorice_color;
}
.bg--white-smoke {
	background-color: $white-smoke_color;
}
.bg--whisper {
	background-color: $bg-whisper_color;
}
.bg--mahogany {
	background-color: $bg-mahogany_color;
}
.bg--supernova {
	background-color: $bg-supernova_color;
}
.bg--kelly_green {
	background-color: $bg-kelly-green_color;
}
.bg--tangerine_yellow {
	background-color: $primary-color !important;
}
.bg--red {
	background-color: $bg-red_color;
}
.bg--snow_color {
	background-color: $bg-snow_color;
}

/* ---All Default Color Variation Included In This Area---↓ */
@for $i from 1 through length($colorList) {
	.template-color-#{$i} {
		// Default Anchor Tag Color
		a {
			&:hover {
				color: nth($colorList, $i) !important;
			}
			&.umino-banner_btn {
				&:hover {
					color: $bg-white_color !important;
				}
			}
			&.umino-btn_limerick {
				&:hover {
					color: $bg-white_color !important;
				}
			}
			&.umino-btn_dark {
				&:hover {
					background-color: nth($colorList, $i);
					color: $bg-white_color !important;
				}
			}
			&.umino-btn_yellow {
				background-color: $primary-color;
				&:hover {
					background-color: $heading-color;
					color: $bg-white_color !important;
				}
			}
		}
		/* ---Umino's Newsletters Popup--- */
		.popup_wrapper {
			.test {
				.popup_off {
					&:hover {
						background: nth($colorList, $i);
					}
				}
			}
			.subscribe_area {
				.subscribe-form-group {
					form {
						button {
							&:hover {
								background: nth($colorList, $i);
								color: $bg-white_color;
							}
						}
					}
				}
			}
		}
		/* ---Umino's Header Bottom Element Color--- */
		.header-bottom_area {
			&.bg--tangerine_yellow {
				.main-menu_area {
					.main_nav {
						> ul {
							> li {
								&.active {
									> a {
										color: $bg-white_color;
									}
								}
								&:hover {
									> a {
										color: $bg-white_color !important;
									}
								}
							}
						}
					}
				}
				.login-area {
					> a {
						&:hover {
							color: $bg-white_color !important;
						}
					}
				}
			}
		}
		/* ---Umino's Main Menu Color Variant--- */
		.main-menu_area {
			.main_nav {
				> ul {
					> li {
						&.active {
							> a {
								color: $primary-color;
								&:before {
									width: 100%;
									opacity: 1;
								}
							}
						}
						.hm-dropdown {
							> li {
								&.active {
									> a {
										color: $primary-color;
									}
								}
							}
						}
						.umino-megamenu {
							> li {
								> ul {
									> li {
										&.active {
											> a {
												color: $primary-color;
											}
										}
									}
								}
							}
						}
						&:hover {
							> a {
								color: $primary-color;
							}
						}
					}
				}
			}
		}
		/* ---Default Slider Element Color--- */
		.main-slider {
			.slider-content {
				.umino-btn {
					&:hover {
						box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
						color: $bg-white_color !important;
						background-color: lighten($primary-color, 7);
					}
				}
			}
			.slick-arrow {
				color: nth($colorList, $i);
			}
		}
		/* ---Umino's Product Slider--- */
		.umino-product_slider,
		.umino-product_slider-2,
		.umino-product_slider-3,
		.umino-product_slider-4,
		.umino-product_slider-5,
		.list-product_slider,
		.list-product_slider-2,
		.umino-product-tab_slider,
		.umino-content_wrapper {
			.slide-item {
				.single-product {
					border: 1px solid $border-color;
					&:hover {
						border-color: nth($colorList, $i);
					}
				}
			}
		}
		/* ---Social Link Element Color--- */
		.umino-social_link {
			> ul {
				> li {
					> a {
						&:hover {
							background-color: nth($colorList, $i);
						}
					}
				}
			}
		}
		.footer-bottom_area {
			.footer-bottom_nav {
				.copyright {
					> span {
						> a {
							color: nth($colorList, $i);
						}
					}
				}
			}
		}
		/* ---Default ScrollUp Element Color--- */
		#scrollUp {
			background-color: $heading-color;
			color: $bg-white_color;
			&:hover {
				background-color: nth($colorList, $i);
				border-color: nth($colorList, $i);
				color: $bg-white_color !important;
			}
		}
		/* ---Umino's Tooltip--- */
		.tooltip-inner {
			background-color: $heading-color;
		}
		.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
		.tooltip.bs-tooltip-top .arrow::before {
			border-top-color: $heading-color;
		}
		.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
		.bs-tooltip-bottom .arrow::before {
			border-bottom-color: $heading-color;
		}
		/* ---Preloader--- */
		.loading {
			.middle {
				.lds-ellipsis {
					div {
						background: nth($colorList, $i);
					}
				}
			}
		}
		/* ---Color List Area--- */
		.color-list {
			.single-color {
				&.active {
					border-color: nth($colorList, $i);
				}
			}
		}
		/* ---Quick View Close Button Color--- */
		.modal-body {
			.close {
				&:hover {
					color: nth($colorList, $i);
				}
			}
		}
		/* ---Single Product Slider Area Border Color--- */
		.sp-slider {
			.slide-item {
				.single-product {
					border: 1px solid $border-color;
					&:hover {
						border-color: nth($colorList, $i);
					}
				}
			}
		}
		/* ---Single Product Gallery Outline Color--- */
		.sp-gallery_area {
			.sp-nav {
				.sp-gallery {
					.lg-image {
						outline: 1px solid $border-color;
						&:hover {
							outline: 1px solid nth($colorList, $i);
						}
					}
				}
			}
		}
	}
}
