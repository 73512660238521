/*----------------------------------------*/
/*  29 - Pages - Shop
/*----------------------------------------*/
.umino-content_wrapper {
	padding: 55px 0 60px;
	/* ---Umino's Sidebar Categories--- */
	.umino-sidebar-catagories_area {
		@include breakpoint (max-medium) {
			padding-top: 60px;
		}
		.umino-sidebar_categories {
			background-color: $bg-white_color;
			.umino-categories_title {
				border-bottom: 1px solid $border-color;
				position: relative;
				padding-top: 20px;
				margin-bottom: 20px;
				text-align: center;
				&.first-child {
					padding-top: 0;
				}
				h5 {
					margin-bottom: 0;
					padding-bottom: 25px;
					&:after {
						height: 2px;
						bottom: -2px;
					}
				}
				&.umino-tags_title {
					margin-bottom: 30px;
					padding-top: 25px;
				}
			}
			.sidebar-checkbox_list {
				> li {
					padding-bottom: 15px;
					&:first-child {
						padding-top: 0;
					}
					&:last-child {
						padding-bottom: 0;
					}
					> a {
						vertical-align: middle;
					}
				}
			}
			.sidebar-categories_menu {
				ul {
					li {
						cursor: pointer;
						&:first-child {
							padding-top: 20px;
						}
						&:last-child {
							> a {
								padding-bottom: 0;
							}
						}
						&.has-sub {
							ul {
								> li {
									&:first-child {
										padding-top: 0;
									}
								}
							}
						}
						&:hover {
							> a {
								color: $primary-color;
								> i {
									color: $primary-color;
								}
							}
						}
						> ul {
							display: none;
							li {
								@extend %basetransition;
								a {
									display: inline-block;
									padding-bottom: 20px;
									padding-left: 15px;
									color: $bg-nobel_color;
								}
								&:last-child {
									> a {
										padding-bottom: 25px;
									}
								}
							}
						}
						> a {
							display: block;
							padding-bottom: 20px;
							position: relative;
							line-height: 1;
							> i {
								font-size: 16px;
								position: absolute;
								right: 0;
								top: 0;
								transform: rotate(0deg);
								@extend %basetransition;
								color: $bg-nobel_color;
							}
						}
						&.open {
							> a {
								> i {
									transform: rotate(90deg);
								}
							}
						}
					}
				}
			}
			&.sidebar-banner_area {
				padding-top: 30px;
				&.umino-banner_area {
					.banner-item {
						.banner-content-2 {
							@include absCenter;
							width: 70%;
							text-align: center;
							@include breakpoint(max-normal) {
								width: 90%;
							}
						}
					}
				}
			}
			&.umino-list-product_area {
				padding-top: 5px;
				padding-bottom: 0;
				.umino-categories_title {
					margin-bottom: 30px;
				}
				.list-product_slider {
					.slide-item {
						.single-product {
							.product-img {
								width: 70px;
								@include breakpoint (normal) {
									width: 60px;
								}
							}
							.product-content {
								width: calc(100% - 70px);
								@include breakpoint (normal) {
									width: calc(100% - 60px);
								}
								> h6 {
									&.product-name {
										margin-bottom: 0;
										padding-bottom: 10px;
										@include breakpoint(normal) {
											font-size: 13px;
										}
									}
								}
								.price-box {
									@include new-price;
									.new-price {
										@include breakpoint (normal) {
											font-size: 13px;
										}
									}
									@include new-price;
									.new-price {
										@include breakpoint (normal) {
											font-size: 13px;
										}
									}
								}
								.rating-box {
									padding-bottom: 10px;
									> ul {
										> li {
											display: inline-block;
											> i {
												color: $primary-color;
											}
										}
									}
								}
							}
						}
					}
					.slick-arrow {
						top: -80px;
					}
				}
				&.compare-list-product_area {
					padding-top: 0;
				}
			}
		}
	}
	/* ---Shop Banner Area--- */
	.shop-banner_area {
		padding-bottom: 25px;
	}
	/* ---Shop Toolbar--- */
	.shop-toolbar {
		display: flex;
		justify-content: space-between;
		padding: 0 0 25px 0;
		@include breakpoint (max-small) {
			display: block;
		}
		.product-view-mode {
			display: flex;
			align-items: center;
			@include breakpoint (max-small) {
				justify-content: center;
			}
			> a {
				color: $bg-nobel_color;
				display: inline-block;
				cursor: pointer;
				font-size: 24px;
				&.active {
					color: $primary-color;
				}
				> i {
					padding-right: 10px;
				}
				&:last-child {
					> i {
						padding-right: 0;
					}
				}
			}
		}
		.product-item-selection_area {
			display: flex;
			@include breakpoint (max-small) {
				padding-top: 30px;
				display: block;
				text-align: center;
			}
			.product-short {
				display: flex;
				align-items: center;
				@include breakpoint (max-small) {
					justify-content: center;
				}
				> label {
					margin-bottom: 0;
					padding-right: 25px;
					@include breakpoint (max-medium) {
						padding-right: 10px;
					}
				}
				.nice-select {
					width: 180px;
					height: 35px;
					line-height: 31px;
					.list {
						width: 100%;
					}
				}
			}
			.product-showing {
				display: flex;
				align-items: center;
				padding-left: 25px;
				@include breakpoint (max-small) {
					justify-content: center;
					padding: 30px 0 0 0;
				}
				> label {
					margin-bottom: 0;
					padding-right: 25px;
					@include breakpoint (max-medium) {
						padding-right: 10px;
					}
				}
				.myniceselect {
					&.nice-select {
						span {
							height: 40px;
							line-height: 40px;
							@include breakpoint (max-small) {
								padding: 0 35px 0 20px;
							}
						}
						.list {
							@include breakpoint (max-small) {
								width: auto;
								right: 0;
								left: auto;
							}
						}
					}
				}
			}
		}
		.product-page_count {
			display: flex;
			align-items: center;
			@include breakpoint (max-small) {
				justify-content: center;
				padding: 25px 0 0 0;
			}
			> p {
				margin-bottom: 0;
			}
		}
	}
	/* ---Shop Product Wrap--- */
	.shop-product-wrap {
		&.grid {
			[class*="col-"] {
				&:not(:nth-child(-n + 3)) {
					.slide-item {
						.single-product {
							margin-top: 30px;
						}
					}
				}
				&:not(:nth-child(-n + 2)) {
					.slide-item {
						.single-product {
							@include breakpoint(max-small) {
								margin-top: 30px;
							}
						}
					}
				}
				&:not(:last-child) {
					.slide-item {
						.single-product {
							@include breakpoint(max-x_small) {
								margin-top: 30px;
							}
						}
					}
				}
			}
			.list-slide_item {
				display: none;
			}
			.slide-item {
				display: block;
				.single-product {
					overflow: hidden;
					text-align: center;
					.product-img {
						overflow: visible;
						img {
							width: 100%;
						}
						.add-actions {
							> ul {
								> li {
									> a {
										@include breakpoint (max-medium) {
											width: 35px;
											height: 35px;
											line-height: 35px;
										}
									}
								}
							}
						}
					}
				}
			}
			&.gridview {
				&-2 {
					& > [class*="col-"] {
						@include flex(0 0 50%);
						max-width: 50%;
						@include breakpoint (max-x_small) {
							@include flex(0 0 100%);
							max-width: 100%;
						}
					}
				}
				&-3 {
					& > [class*="col-"] {
						@include flex(0 0 33.333333%);
						max-width: 33.333333%;
						@include breakpoint (small) {
							@include flex(0 0 50%);
							max-width: 50%;
						}
						@include breakpoint (x-small) {
							@include flex(0 0 50%);
							max-width: 50%;
						}
						@include breakpoint (xx-small) {
							@include flex(0 0 100%);
							max-width: 100%;
						}
					}
				}
				&-4 {
					& > [class*="col-"] {
						@include flex(0 0 25%);
						max-width: 25%;
					}
				}
				&-5 {
					& > [class*="col-"] {
						@include flex(0 0 20%);
						max-width: 20%;
					}
				}
			}
		}
		&.listview {
			& > [class*="col-"] {
				@include flex(1 0 100%);
				width: 100%;
				max-width: 100%;
				.list-slide_item {
					display: block;
					.single-product {
						background-color: $bg-white_color;
						border: 1px solid $border-color;
						padding: 25px 25px 5px;
						overflow: hidden;
						display: flex;
						@include breakpoint (max-normal) {
							padding: 25px;
						}
						@include breakpoint (max-small) {
							display: block;
							padding: 15px 15px 30px;
						}
						.product-img {
							position: relative;
							width: 270px;
							@include breakpoint (max-small) {
								width: auto;
							}
							> a {
								display: block;
								img {
									width: 100%;
								}
							}
						}
						.umino-product-content {
							width: calc(100% - 270px);
							padding-left: 15px;
							@include breakpoint(max-small) {
								width: auto;
								padding-left: 0;
							}
							.product-desc_info {
								> h6 {
									margin-bottom: 0;
									padding-bottom: 10px;
								}
								.rating-box {
									padding-bottom: 5px;
									> ul {
										> li {
											display: inline-block;
											> i {
												color: $bg-supernova_color;
											}
										}
									}
								}
								.price-box {
									padding-bottom: 5px;
									@include old-price;
									.old-price {
										display: inline-block;
									}
									@include new-price;
								}
								.product-short_desc {
									> p {
										margin-bottom: 0;
										padding-bottom: 23px;
									}
								}
							}
							.add-actions {
								> ul {
									> li {
										display: inline-block;
										padding-left: 5px;
										> a {
											background-color: $primary-color;
											border-radius: 100%;
											display: block;
											width: 40px;
											height: 40px;
											line-height: 40px;
											text-align: center;
											&:hover {
												background-color: $bg-red_color;
												color: $bg-white_color !important;
											}
											> i {
												font-size: 20px;
											}
										}
									}
								}
							}
						}
					}
				}
				.slide-item {
					display: none;
				}
				&:not(:last-child) {
					.list-slide_item {
						.single-product {
							margin-bottom: 30px;
						}
					}
				}
			}
		}
		& > [class*="col-"] {
			@extend %basetransition;
		}
	}
	/* ---Shop Paginatoin Area--- */
	.umino-paginatoin-area {
		padding-top: 30px;
		.umino-pagination-box {
			@include breakpoint (max-x_small) {
				display: flex;
				justify-content: center;
			}
			@include breakpoint(xx-small) {
				display: block;
			}
			> li {
				@include breakpoint (max-x_small) {
					margin-right: 10px;
				}
				@include breakpoint (max-x_small) {
					&:last-child {
						margin-right: 0;
					}
				}
				@include breakpoint(xx-small) {
					margin-right: 0;
				}
				> a {
					@include breakpoint(xx-small) {
						padding: 0 10px;
					}
				}
			}
		}
	}
}
